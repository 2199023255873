import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography } from "@mui/material";

function Product({ categories, heading }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="slider-container"
      style={{
        width: "100%",
        padding: "0 15px",
        backgroundColor: "white",
        height: "auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          paddingTop: "1rem",
          fontWeight: "bold",
          color: "#333333",
          textAlign: "center",
        }}
      >
        {heading}
      </Typography>
      <Slider {...settings} style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        {categories.map((category, index) => (
          <div key={index} style={{ padding: "0 10px" }}>
            <div
              style={{
                textAlign: "center",
                backgroundColor: "white",
                borderRadius: "8px",
                padding: "10px",
                height: "250px", // Fixed height for consistency
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="image-container"
            >
              <img
                src={category.image}
                alt={category.name}
              
                className="zoom-image"
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#333333",
                  marginTop: "10px",
                }}
              >
                {category.name}
              </Typography>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Product;
