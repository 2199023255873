import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const pages = ["Home", "About Us", "Products", "Contact Us"];
const productOptions = ["Jewelry", "Scarf"];

function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElProducts, setAnchorElProducts] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenProductsMenu = (event) => {
    setAnchorElProducts(event.currentTarget);
  };

  const handleCloseProductsMenu = () => {
    setAnchorElProducts(null);
  };

  return (
    <AppBar position="static" className="nac">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="a"
            href=""
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              mr: 1,
            }}
          >
            <img
              src="dipisha.png"
              alt="Dipisha Logo"
              style={{ height: "70px", margin: "1.5px" }}
            />
          </Box>

          {/* Company Name */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              fontWeight: "bold",
              color: "black",
              display: { xs: "none", md: "block" }, // Hide on small screens
            }}
            className="cmp"
          >
            Dipisha Fashline Enterprises LLP
          </Typography>

          {/* Responsive Menu Icon and Menu */}
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "flex-end",
              flexGrow: 1, // Ensure space is taken up so the icon stays right-aligned
            }}
          >
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  backgroundColor: "#d8e6ed", // Set background color
                },
              }}
            >
              {pages.map((page) =>
                page === "Products" ? (
                  <MenuItem key={page} onClick={handleOpenProductsMenu}>
                    <Typography
                      variant="body1"
                      textAlign="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={page}
                    onClick={() => {
                      navigate(
                        page === "Home"
                          ? "/"
                          : `/${page.toLowerCase().replace(/ /g, "-")}`
                      );
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography
                      variant="body1"
                      textAlign="center"
                      sx={{ fontWeight: "bold" }}
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>

          {/* Main Navigation Links (visible on medium screens and above) */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {pages.map((page) =>
              page === "Products" ? (
                <div key={page}>
                  <Button
                    onClick={handleOpenProductsMenu}
                    sx={{
                      my: 0,
                      color: "white",
                      display: "block",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    className="nav"
                  >
                    {page}
                  </Button>
                  <Menu
                    id="products-menu"
                    anchorEl={anchorElProducts}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElProducts)}
                    onClose={handleCloseProductsMenu}
                    sx={{
                      mt: 1,
                      ml: 2,
                      "& .MuiPaper-root": {
                        backgroundColor: "#d8e6ed", // Set background color
                      },
                    }}
                  >
                    {productOptions.map((option) => (
                      <MenuItem
                        key={option}
                        onClick={() => {
                          navigate(
                            `/${option.toLowerCase().replace(/ /g, "-")}`
                          );
                          handleCloseProductsMenu();
                        }}
                        sx={{ color: "black" }}
                      >
                        {""}
                        <ArrowForwardIosIcon />
                        &nbsp;
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              ) : (
                <Button
                  key={page}
                  onClick={() => {
                    navigate(
                      page === "Home"
                        ? "/"
                        : `/${page.toLowerCase().replace(/ /g, "-")}`
                    );
                  }}
                  sx={{
                    my: 0,
                    color: "white",
                    display: "block",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  className="nav"
                >
                  {page}
                </Button>
              )
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
