import React from "react";
import { Typography, Box, Grid } from "@mui/material";

const Customization1 = () => {
  return (
    <Box sx={{ padding: "2rem", backgroundColor: "#112C50" }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{ color: "#EF6602", marginBottom: "1rem", textAlign: "center",fontWeight:"bold" }}
      >
        Custom Scarves
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <Box
            component="img"
            src="Designer1.png"
            alt="Customization"
            sx={{
              width: "100%",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            variant="body1"
            sx={{
              color: "#E8E8E8",
              marginTop: "0.5rem",
              fontSize: "1.2rem", // Increase font size
              lineHeight: "2.5", // Increase line height
              textAlign: "justify", // Optional: justify text alignment for a cleaner look
            }}
          >
            Our bespoke scarf service allows clients to choose from a wide array
            of fabrics, like Rayon, Polyester, Cotton, Blends and more.
            Customers can select their preferred patterns, colors, and finishes
            to create a truly unique accessory. Whether it’s for a Brand, a
            corporate gift, we work closely with our clients to bring their
            vision to life
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Customization1;
