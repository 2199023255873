import React from "react";
import { Typography, Box, Grid } from "@mui/material";

const Customization2 = () => {
  return (
    <Box sx={{ padding: "2rem", backgroundColor: "#E8E8E8" }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{ color: "#EF6602", marginBottom: "1rem", textAlign: "center",fontWeight:"bold" }}
      >
        Custom Jewelery
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <Box
            component="img"
            src="Designer2.png"
            alt="Customization"
            sx={{
              width: "100%",
              borderRadius: "8px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            variant="body1"
            sx={{
              color: "#1e2b46",
              marginTop: "0.5rem",
              fontSize: "1.2rem", // Increase font size
              lineHeight: "2.5", // Increase line height
              textAlign: "justify", // Optional: justify text alignment for a cleaner look
            }}
          >
            Our customization service for jewelry is designed to provide a
            personalized touch to each piece. Clients can choose from a variety
            of metals, gemstones, and designs to create custom necklaces,
            bracelets, earrings, and rings. Our skilled artisans work diligently
            to craft pieces that match the exact specifications and desires of
            our clients. From initial design sketches to the final polished
            product, we ensure that every detail is perfect, creating jewelry
            that not only complements the individual’s taste but also tells
            their unique story.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Customization2;
