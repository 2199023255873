import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import HomePage from "./Components/HomePage";
import Feed from "./Components/Feed";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Footer from "./Components/Footer";
import Nav from "./Components/Nav";
import Contact from "./Components/Contact";
import ProductJ from "./Components/ProductJ";
import Scarf from "./Components/Scarf";
function App() {
  return (
    <BrowserRouter>
      <Nav />

      <Routes>
        <Route path="/about-us" element={<Feed />} />

        <Route path="/" element={<HomePage />} />

        <Route path="/contact-us" element={<Contact></Contact>} />
        <Route path="/jewelry" element={<ProductJ></ProductJ>} />
        <Route path="/scarf" element={<Scarf></Scarf>} />
      </Routes>

      <Footer></Footer>
    </BrowserRouter>
  ); 
}

export default App;
