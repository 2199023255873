import React from "react";
import Product from "./Product"; // Adjust the import path as needed

const categoriesNecklace = [
  { name: "", image: "n1.jpeg" },
  { name: "", image: "n2.jpeg" },
  { name: "", image: "n3.jpeg" },
  { name: "", image: "n4.jpeg" },
  { name: "", image: "n5.jpeg" },
  { name: "", image: "n6.jpeg" },
  { name: "", image: "n7.jpeg" },
  { name: "", image: "n8.jpeg" },
  { name: "", image: "n9.jpg" },
  { name: "", image: "n10.jpg" },
  { name: "", image: "n11.jpg" },
  { name: "", image: "n12.jpg" },
  { name: "", image: "n13.jpg" },
  { name: "", image: "n14.jpg" },
  { name: "", image: "n15.jpg" },
];
const Earings = [
  { name: "", image: "e1.png" },
  { name: "", image: "e2.png" },
  { name: "", image: "e3.png" },
  { name: "", image: "e4.png" },
  { name: "", image: "e5.png" },
  { name: "", image: "e6.png" },
  { name: "", image: "e7.png" },
  { name: "", image: "e8.png" },

];
const Bags = [
  { name: "", image: "b1.jpeg" },
  { name: "", image: "b2.png" },
  { name: "", image: "b3.jpeg" },
  { name: "", image: "b4.png" },
  { name: "", image: "b5.jpeg" },
  { name: "", image: "b6.png" },
  { name: "", image: "b7.jpeg" },
  { name: "", image: "b8.png" },

  { name: "", image: "b9.jpeg" },
  { name: "", image: "b10.png" },
  { name: "", image: "b11.png" },
  { name: "", image: "b12.png" },
  { name: "", image: "b13.png" },
];
const Hair = [
  { name: "", image: "h1.jpeg" },
  { name: "", image: "h2.png" },
  { name: "", image: "h3.jpeg" },
  { name: "", image: "h4.png" },
  { name: "", image: "h5.png" },
  { name: "", image: "h6.png" },
  { name: "", image: "h7.png" },
  { name: "", image: "h8.png" },

  { name: "", image: "h9.png" },
  { name: "", image: "h10.png" },
  { name: "", image: "h11.png" },
  { name: "", image: "h12.png" },
  { name: "", image: "h13.png" },

  { name: "", image: "h14.png" },
  { name: "", image: "h15.png" },
  { name: "", image: "h16.png" },
  { name: "", image: "h17.png" },
  { name: "", image: "h19.png" },
  { name: "", image: "h20.png" },
];
const Bangles = [
  { name: "", image: "g1.png" },
  { name: "", image: "g2.png" },
  { name: "", image: "g3.png" },
  { name: "", image: "g4.png" },
  { name: "", image: "g5.png" },
];
function ProductJ() {
  return (
    <div>
      <Product categories={categoriesNecklace} heading="Necklace" />
      <Product categories={Earings} heading="Earings" />
      <Product categories={Bags} heading="Bags" />
      <Product categories={Bangles} heading="Bangles" />
      <Product categories={Hair} heading="Hair Accessories" />
    </div>
  );
}

export default ProductJ;
