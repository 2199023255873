import React from "react";
import { Typography, Box, Grid } from "@mui/material";

function Feed() {
  return (
    <>
      <Box sx={{ padding: "2rem", backgroundColor: "#112C50" }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            color: "#FF8C00",
            marginBottom: "1rem",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          About Us
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src="about.png"
              alt="Customization"
              sx={{
                width: "100%",
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="body1"
              sx={{
                color: "#E8E8E8",
                marginTop: "0.5rem",
                fontSize: "1.2rem",
                lineHeight: "2.5",
                textAlign: {
                  xs: "left",
                  md: "justify",
                },
                fontWeight: "600",
                hyphens: "auto",
              }}
            >
              At Dipisha Fashline Enterprises LLP, we are dedicated to
              excellence in every facet of our business. As we navigate the
              dynamic landscape of global fashion, we remain steadfast in our
              core principles and objectives. Below, we outline the mission and
              vision that drive our efforts and shape our path forward.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: "2rem", backgroundColor: "#E8E8E8" }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            color: "#FF8C00",
            marginBottom: "1rem",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
         Our Mission 
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src="mission.png"
              alt="Customization"
              sx={{
                width: "100%",
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="body1"
              sx={{
                color: "#1e2b46",
                marginTop: "0.5rem",
                fontSize: "1.2rem",
                lineHeight: "2.5",
                textAlign: {
                  xs: "left",
                  md: "justify",
                },
                fontWeight: "600",
                hyphens: "auto",
              }}
            >
              Our mission is to provide the highest quality scarves and jewelry
              to our global customers by blending traditional craftsmanship with
              contemporary designs. We are committed to delivering exceptional
              products that inspire and enhance the beauty of our customers. We
              strive to build long-lasting relationships based on trust,
              integrity, and customer satisfaction while fostering sustainable
              and ethical practices throughout our supply chain.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: "2rem", backgroundColor: "#112C50" }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            color: "#FF8C00",
            marginBottom: "1rem",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
        Our Vision 
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src="vision.png"
              alt="Customization"
              sx={{
                width: "100%",
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="body1"
              sx={{
                color: "#E8E8E8",
                marginTop: "0.5rem",
                fontSize: "1.2rem",
                lineHeight: "2.5",
                textAlign: {
                  xs: "left",
                  md: "justify",
                },
                fontWeight: "600",
                hyphens: "auto",
              }}
            >
              Our vision is to be a leading global exporter of scarves and
              jewelry, recognized for our innovation, quality, and commitment to
              sustainability. We aim to continuously evolve with the fashion
              industry, setting trends and standards that others aspire to
              follow. By empowering our artisans and embracing eco-friendly
              practices, we envision a future where our brand is synonymous with
              elegance, ethical production, and global appeal.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Feed;
