import React from 'react'
import Nav from './Nav'
import CategoryCarousel from "./CategoryCar";
import Customization from './Custom1';
import Customization1 from "./Custom2";
import Customization2 from "./Custom3";
import Carousel from './Crousal'
function HomePage() {
  return (
    <>
     <Carousel></Carousel>

      <CategoryCarousel></CategoryCarousel>
      <Customization></Customization>
      <Customization1></Customization1>
      <Customization2></Customization2>
     
    </> 
  );
}

export default HomePage